// QuestionInput.jsx
import React, { useState, useEffect } from 'react';
import { TextField, Fab, Grid, FormControl, FormHelperText, LinearProgress } from '@mui/material';
import SendIcon from '@mui/icons-material/Send';

const QuestionInput = ({ setLatestQuestion, setVectorSearchResponse, setAiResponse, exampleQuestion, customerName }) => {
    const [inputValue, setInputValue] = useState('');
    const [error, setError] = useState('');
    const [isSubmitting, setIsSubmitting] = useState(false);

    const apiKeys = {
        "eslov": "U5dfsxQi1NhHCR0XXuleLGlPBliHRwH9r9kIePKtKS8AhrjxNK0pRlpOyxOC9hVJ",
        "hassleholm": "2zgtIuRHtBkrkcJh2MIOS68lzqOA2OPRCbneKtUfQCPAGiNiXZAbMeEqlnKBCve9",
        "hoganas": "KryQ8Loiy8ZI9KDCzhGGBG1ybpkcAV4MsRAFR3m4yosdSTtnDpsfrODfREfydBkd",
        "alvesta": "vjVkxOMGU4Fk4JTLAxOLOaNKqNVU9WzrDNCMMoY3uyrPnkP8ODkEEIAuj9zt14Nm"
    }

    useEffect( () => {
        setInputValue(exampleQuestion)
    }, [exampleQuestion])

    const handleInputChange = (event) => {
        setInputValue(event.target.value);
        setError('');
    };

    const handleSubmit = async () => {
        setError('')

        if (inputValue.trim() === '') {
            setError('Du har inte frågat något.');
            return;
        } else if (inputValue.trim().length > 100) {
            setError('Input får inte vara längre än 100 tecken.');
            return;
        }
        
        setIsSubmitting(true);

        try {
            // Define form data for the requests
            const formData = {
                question: inputValue,
                model: 'gpt-4o',  // Replace with actual model if needed
                strategy: 'eslov-markdown',  // Replace with actual strategy if needed
            };

            // Perform the API requests
            // local: http://0.0.0.0:80
            // prod: https://api.redbar.tech
            const aiRequest = fetch('https://api.redbar.tech/gai', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'ApiKey': apiKeys[customerName] // Include API key in the ApiKey header
                },
                body: JSON.stringify({
                    input: formData.question,
                    customer_name: customerName,
                }),
            });

            const vectorSearchRequest = fetch(`https://api.redbar.tech/gvsearch/${encodeURIComponent(formData.question)}/${customerName}`, {
                method: 'GET',
                headers: {
                  'Content-Type': 'application/json',
                  'ApiKey': apiKeys[customerName] // Include API key in the ApiKey header
                }
              });

            // Wait for both requests to complete
            const [aiResponse, vectorSearchResponse] = await Promise.all([aiRequest, vectorSearchRequest]);

            // Check for specific status codes and handle errors
            if (aiResponse.status === 400) {
                const errorBody = await aiResponse.json()
                throw new Error(errorBody.detail);
            } else if (!aiResponse.ok) {
                throw new Error('AI request failed');
            }

            if (vectorSearchResponse.status === 400) {
                throw new Error('Vector search request error: Bad Request (400)');
            } else if (!vectorSearchResponse.ok) {
                throw new Error('Vector search request failed');
            }

            // Parse the responses
            const aiData = await aiResponse.json();
            const vectorSearchData = await vectorSearchResponse.json();

            // Update the state with the responses
            setLatestQuestion(inputValue);
            setAiResponse(aiData.generated_response);
            setVectorSearchResponse(vectorSearchData);

            // Clear the input and error state
            setInputValue('');
            setError('');
        } catch (error) {
            setError(error.message || 'Failed to submit. Please try again.');
        } finally {
            setIsSubmitting(false);
        }
    };

    return (
        <FormControl fullWidth error={!!error}>
            <Grid container justifyContent="flex-start" alignItems="center" width="100%">
                <Grid item xs={10}>
                    <TextField
                        label="Fråga..."
                        variant="outlined"
                        value={inputValue}
                        onChange={handleInputChange}
                        fullWidth
                        disabled={isSubmitting}
                    />
                </Grid>
                <Grid item xs={2} container justifyContent="flex-end">
                    <Fab
                        aria-label="send"
                        size="large"
                        sx={{ boxShadow: '0px 0px 2px 0px' }}
                        onClick={handleSubmit}
                        disabled={isSubmitting}
                        >
                        <SendIcon />
                    </Fab>
                </Grid>
                <Grid item xs={10}>
                {error && <FormHelperText>{error}</FormHelperText>}
                </Grid>
                <Grid item xs={10}>
                    {isSubmitting ? <LinearProgress /> : null}
                </Grid>
            </Grid>
            
        </FormControl>
    );
};

export default QuestionInput;
